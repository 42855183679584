import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import { Icon, Typography, Stack, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useIsMobile } from '../../../../hooks';
import { InsuranceProvider } from '../../domain';

// ********************************************************************************
const Benefits = ({ provider }: { provider: InsuranceProvider }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const properties = t(`insurance_service.providers.${provider}.benefits`, {
    returnObjects: true,
  }) as string[];

  // Split benefits in columns of the same size
  const COLUMN_SIZE =  Math.ceil(properties.length / 2); // 2 columns
  // e.g. CS = 3, [1, 2, 3, 4] = [[1, 2, 3], [4]]
  const splitedBenefits = properties.reduce((acc, item, index) => {
    const currentColumn = Math.floor(index / COLUMN_SIZE);
    if (!acc[currentColumn]) {
      acc[currentColumn] = [];
    }
    acc[currentColumn].push(item);
    return acc;
  }, [] as string[][]);

  return (
    <>
      <Grid container width="100%" direction={isMobile ? 'column' : 'row'} gap={isMobile ? '.3em' : 0}>
        {splitedBenefits.map((benefits, index) => (
          <Grid item xs={6} paddingLeft="calc(1rem + 50px)" display="flex" flexDirection="column" gap=".4rem">
            {benefits.map((item, index) => (
              <Stack display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" gap=".6rem" key={'benefits-2-' + index} paddingRight="1rem">
                <Icon
                  component={CheckIcon}
                  sx={{
                    color: '#EF60A3',
                    fontSize: '1rem',
                  }}
                />

                <Typography
                  variant="caption"
                  width={isMobile ? '38vw' : 'max-content'}
                  maxWidth={isMobile ? '38vw' : 'max-content'}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '80vh',
                  }}
                >
                  {item}
                </Typography>
              </Stack>
            ))}
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default Benefits;
