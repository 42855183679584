import dayjs from "dayjs";

export const calculateEndDate = (startDate: string, coverageDays: number): string => {
  const startDateParts = startDate.split('-');
  const startDateObject = new Date(
    parseInt(startDateParts[0]), // Year
    parseInt(startDateParts[1]) - 1, // Month (0-based)
    parseInt(startDateParts[2]), // Day
  );

  startDateObject.setDate(startDateObject.getDate() + coverageDays - 1 /* start date is included */);

  // Ensure proper formatting with leading zeros
  const year = startDateObject.getFullYear();
  const month = String(startDateObject.getMonth() + 1).padStart(2, '0'); // Ensure 2 digits
  const day = String(startDateObject.getDate()).padStart(2, '0'); // Ensure 2 digits

  return `${year}-${month}-${day}`;
};

// Departure Date can be a Date object or a string (depending on the source). If it's a Date object,
// convert it to a string.
export const formatDateToString = (date: string | Date): string => {
  if (typeof date === 'object') date = date.toISOString();
  return date.split('T')[0]; // Remove the time part
};

// Format a date string from YYYY-MM-DD to MM/DD/YYYY
// NOTE: Not using .toLocaleDateString() as there's no timezone information in the string,
//       and that can lead to inconsistencies in formatting.
export function MMDDYYYY(dateString: string): string {
  return dayjs(dateString).format('M/D/YYYY');
}
