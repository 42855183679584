export enum TicketSeason {
  Low = 'low',
  Regular = 'regular',
  High = 'high',
}

export const END_OF_MONTH = null;

const TICKET_SEASONS_BY_MONTHS = {
  0 /** January */: {
    [TicketSeason.High]: [{ from: 1, to: 16 }],
    [TicketSeason.Low]: [{ from: 17, to: 28 }],
    [TicketSeason.Regular]: [{ from: 29, to: END_OF_MONTH }],
  },
  1 /** February */: {
    [TicketSeason.Regular]: [
      { from: 1, to: 4 },
      { from: 5, to: 11 },
      { from: 12, to: 18 },
    ],
    [TicketSeason.Low]: [
      { from: 19, to: END_OF_MONTH },
    ],
  },
  2 /** March */: {
    [TicketSeason.Low]: [{ from: 1, to: 11 }, { from: 19, to: END_OF_MONTH }],
    [TicketSeason.Regular]: [{ from: 12, to: 18 }],
  },
  3 /** April */: {
    [TicketSeason.Regular]: [{ from: 9, to: END_OF_MONTH }],
    [TicketSeason.Low]: [{ from: 1, to: 8 }],
  },
  4 /** May */: {
    [TicketSeason.Regular]: [{ from: 1, to: END_OF_MONTH }],
  },
  5 /** June */: {
    [TicketSeason.Regular]: [{ from: 1, to: END_OF_MONTH }],
  },
  6 /** July */: {
    [TicketSeason.Regular]: [{ from: 1, to: END_OF_MONTH }],
  },
  7 /** August */: {
    [TicketSeason.Regular]: [
      { from: 1, to: 12 },
      { from: 27, to: END_OF_MONTH },
    ],
    [TicketSeason.Low]: [{ from: 13, to: 26 }],
  },
  8 /** September */: {
    [TicketSeason.Regular]: [
      { from: 1, to: 2 },
      { from: 3, to: 9 },
      { from: 10, to: 17 },
    ],
    [TicketSeason.Low]: [
      { from: 18, to: END_OF_MONTH },
    ],
  },
  9 /** October */: {
    [TicketSeason.Low]: [{ from: 1, to: END_OF_MONTH }],
  },
  10 /** November */: {
    [TicketSeason.Low]: [{ from: 1, to: 13 }],
    [TicketSeason.High]: [{ from: 14, to: END_OF_MONTH }],
  },
  11 /** December */: {
    [TicketSeason.High]: [
      { from: 1, to: 2 }, { from: 3, to: 9 },
      { from: 10, to: END_OF_MONTH },
    ],
  },
};

export const getTicketSeasonsByMonth = (month: number) => TICKET_SEASONS_BY_MONTHS[month];
