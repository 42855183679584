import React, { PropsWithChildren, useMemo } from 'react';

import ErrorRoundedIcon from '@mui/icons-material/ErrorRounded';
import { Box, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Trans, useTranslation } from 'react-i18next';

import { Loader } from '../../../../components/Loader';
import useShoppingCartContext from '../../../../context/ShoppingCart/use-shopping-cart-context';
import { ServiceType } from '../../../../domain/common';
import { getContactInformation } from '../../../../domain/checkout';
import { useIsMobile, useStatus } from '../../../../hooks';
import SelectRadioButton from '../../../../legacy/components/base/SelectRadioButton/SelectRadioButton';
import { InsuranceProvider, InsuranceState, isRoundTicketInsurance, isSingleTicketInsurance, SingleTicketInsurance, TicketInsurance } from '../../domain';
import { useInsuranceService } from '../../hooks';
import { calculateEndDate, MMDDYYYY, formatDateToString } from '../../utils/insurance-utils';
import Benefits from './benefits';

// ********************************************************************************
type Props = {
  ticketNumber: number;
  ticket: TicketInsurance;
};

const ExternalLink: React.FC<PropsWithChildren<{ href: string }>> = ({ href, children }) => (
  <Typography variant="caption" fontFamily="Gotham-Bold" sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
    <a href={href} target="_blank" rel="noreferrer">
      {children}
    </a>
  </Typography>
);

export const InsuranceCard: React.FC<Props> = ({ ticket, ticketNumber }) => {
  // ==============================================================================
  const { t } = useTranslation();
  const { cart } = useShoppingCartContext();
  const { language } = useI18next();
  // Find the provider on the first quote that is either PANAMERICAN or BATTLEFACE,
  // if no quotes are found, default to BATTLEFACE
  const provider = ticket.quotes.length > 0 ? ticket.quotes[0].provider : InsuranceProvider.BATTLEFACE;

  const daysLabel = {
    '7': '1-7',
    '15': '8-15',
    '30': '16-30',
    '60': '31-60',
  };
  const days = [7, 15, 30, 60];
  const isMobile = useIsMobile();
  const { onTicketInsuranceChange, onCoverageDaysChange } = useInsuranceService();
  const { isLoading, setStatusLoading, setStatusDone, setStatusError } = useStatus();

  // == Handlers ==================================================================
  const onAccepted = () => {
    onTicketInsuranceChange({ ...ticket, state: InsuranceState.ACCEPTED });
  };

  const onDeclined = () => {
    onTicketInsuranceChange({ ...ticket, state: InsuranceState.DECLINED });
  };

  const handleCoverageDaysChange = async (days: number) => {
    try {
      setStatusLoading();
      await onCoverageDaysChange({ ...ticket, coverageDays: days, endDate: calculateEndDate(ticket.startDate, days) } as SingleTicketInsurance);
      setStatusDone();
    } catch (error) {
      console.error(error);
      setStatusError();
    }
  };

  const passengersFromQuotes = ticket.quotes.map((q) => q.passengersData).flat();
  const passengers = passengersFromQuotes.map((p) => p.firstName + ' ' + p.lastName).join(', ');

  // Those passengers who are on the original ticket but not on the quotes are
  // considered not elegible and will be displayed in a list notifing the user
  // about it.
  const notElegibiblePassengers = useMemo(() => {
    // @ts-ignore
    const ticketInCart = cart.items.find((product) => product.item.type === ServiceType.Ticket && product.item.id === ticket.ticketId);
    const contactInformation = getContactInformation();
    if (!ticketInCart || !contactInformation) return [];

    const ticketPassengers = contactInformation.travelers.find((t) => t.ticketId === ticket.ticketId)?.values;
    if (!ticketPassengers) return [];

    return ticketPassengers.filter((tp) => !passengersFromQuotes.find((p) => p.firstName === tp.firstName && p.lastName === tp.lastName));
  }, [ticket]);

  // == Render ====================================================================
  // TODO: Add a message when there is no available insurance
  if (ticket.total === 0) {
    return null;
  }

  return (
    <Stack display="flex" flexDirection="column" alignItems="center" gap="1rem" padding="1rem" color="#3F2A55">
      <Grid container position="relative" bgcolor="#F6F7FA" width="100%" borderRadius="8px" alignItems="flex-start">
        <Grid item xs={12} md={8} padding="1rem" paddingLeft="calc(1rem + 50px)">
          <Stack display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'flex-start' : 'center'} gap="1rem" color="#EF60A3">
            {/* Badge */}
            <Stack bgcolor="#FFF7FB" borderRadius="8px" border="1px solid #FFD1E7" padding=".4rem .7rem">
              <Typography variant="body2" fontFamily="Gotham-Medium">
                {t('insurance_service.card.trip') + ' ' + ticketNumber}
              </Typography>
            </Stack>
            <Typography variant="body1" fontFamily="Gotham-Bold">
              {passengersFromQuotes.length +
                ' ' +
                t(`insurance_service.card.traveler`, {
                  count: passengersFromQuotes.length,
                }) +
                ' - ' +
                (isRoundTicketInsurance(ticket) ? t('insurance_service.card.travel_type.round_trip') : t('insurance_service.card.travel_type.one_way'))}
            </Typography>
          </Stack>
        </Grid>
        <Stack
          position="absolute"
          right={0}
          top={isMobile ? '1.5rem' : 'calc(50% - 15px)'}
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          paddingRight={isMobile ? '2rem' : 'calc(1rem + 50px)'}
          // paddingLeft={isMobile ? 'calc(1rem + 50px)' : 0}
        >
          {provider === InsuranceProvider.PANAMERICAN && <img src="/images/Insurance/panamerican-logo.svg" alt="Panamerican logo" style={{ width: isMobile ? '25vw' : '12rem' }} />}
          {provider === InsuranceProvider.BATTLEFACE && (
            <img src="/images/Insurance/powered-by-battleface-logo.svg" alt="Battleface logo" style={{ width: isMobile ? '25vw' : '12rem' }} />
          )}
        </Stack>
        <Grid item xs={12} padding="1rem" paddingLeft="calc(1rem + 50px)">
          <Stack display="flex" flexDirection="column" gap=".7rem">
            <Typography variant="body2">{passengers}</Typography>
            <Stack>
              {notElegibiblePassengers.map((p) => (
                <Box display="flex" color="error" alignItems="center" gap=".5rem">
                  <ErrorRoundedIcon color="error" fontSize="small" />
                  <Typography color="error" key={p.firstName + p.lastName} variant="body2" fontFamily="Gotham-Medium">
                    <Trans i18nKey={t(`insurance_service.providers.${[provider]}.not_eligible`)} values={{ name: `${p.firstName} ${p.lastName}` }} />
                  </Typography>
                </Box>
              ))}
            </Stack>
            <Stack display="flex" flexDirection={isMobile ? 'column' : 'row'} gap={isMobile ? '.5rem' : '2rem'}>
              <Stack display="flex" flexDirection="row" gap=".6rem">
                <Typography variant="body2" fontFamily="Gotham-Bold">
                  {t('insurance_service.card.valid_coverage_from')}
                  {':'}
                  <Typography component="span" variant="body2" marginLeft="1rem">
                    {MMDDYYYY(formatDateToString(ticket.startDate))}
                  </Typography>
                </Typography>
              </Stack>
              <Stack display="flex" flexDirection="row" gap=".6rem">
                <Typography variant="body2" fontFamily="Gotham-Bold">
                  {t('insurance_service.card.valid_coverage_to')}
                  {':'}
                  <Typography variant="body2" component="span" marginLeft="1rem">
                    {isSingleTicketInsurance(ticket) ? MMDDYYYY(calculateEndDate(ticket.startDate, ticket.coverageDays)) : MMDDYYYY(ticket.endDate)}
                  </Typography>
                </Typography>
              </Stack>
            </Stack>
            {isSingleTicketInsurance(ticket) && (
              <Stack
                display="flex"
                flexDirection={isMobile ? 'column' : 'row'}
                gap={isMobile ? '1rem' : '3rem'}
                alignItems={isMobile ? 'flex-start' : 'center'}
                justifyContent="flex-start"
              >
                {days.map((d) => (
                  <Stack key={d} display="flex" flexDirection="row" alignItems="center" gap="1rem" onClick={() => handleCoverageDaysChange(d)}>
                    <SelectRadioButton
                      id={`group-${ticket.ticketId}-selected-${d}`}
                      isSelected={`group-${ticket.ticketId}-selected-${ticket.coverageDays}`}
                      selectedColor="#EF60A3"
                    />
                    <Typography variant="caption">{`${daysLabel[`${d}`]} ${t('insurance_service.card.days')}`}</Typography>
                  </Stack>
                ))}
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Benefits provider={provider} />
      {isLoading ? (
        <Box width="100px" margin="auto">
          <Loader />
        </Box>
      ) : (
        <>
          <Stack bgcolor="#F0FFF0" width="100%" padding=".7rem" borderRadius="8px" alignItems="start">
            <Grid container alignItems="center">
              <Grid item xs={6}>
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={
                    <Checkbox
                      checked={ticket.state === InsuranceState.ACCEPTED}
                      onChange={onAccepted}
                      sx={{
                        width: '50px',
                        color: '#6C6D70',
                        '&.Mui-checked': {
                          color: '#3F2A55',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography variant="body1" component="span" display="flex">
                      <Typography variant="body1" component="span" fontFamily="Gotham-Bold">
                        {t('insurance_service.card.checkbox.yes')}
                      </Typography>
                      {', '}
                      {t('insurance_service.card.checkbox.yes_text')}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                <Typography variant="body1" display="flex" gap={isMobile ? '0.1rem' : '0.5rem'} marginRight={isMobile ? '0.2rem' : '4rem'} maxWidth={isMobile ? '100px' : 'auto'}>
                  {t('insurance_service.card.plan_cost')}
                  <Typography variant="body1" fontFamily="Gotham-Bold">
                    {`$${ticket.total?.toFixed(2) ?? 0} USD`}
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          </Stack>
          <Stack bgcolor="#FFDFDE" width="100%" padding=".7rem" borderRadius="8px" alignItems="start" justifyContent="center">
            <FormControlLabel
              style={{ margin: 0 }}
              control={
                <Checkbox
                  checked={ticket.state === InsuranceState.DECLINED}
                  onChange={onDeclined}
                  sx={{
                    width: '50px',
                    color: '#6C6D70',
                    '&.Mui-checked': {
                      color: '#3F2A55',
                    },
                  }}
                />
              }
              label={
                <Typography variant="body1" component="span" display="flex">
                  <Typography variant="body1" component="span" fontFamily="Gotham-Bold">
                    {t('insurance_service.card.checkbox.no')}
                  </Typography>
                  {', '}
                  {t('insurance_service.card.checkbox.no_text')}
                </Typography>
              }
            />
          </Stack>

          <Stack px="1em" width="100%">
            <Typography variant="caption" fontFamily="Gotham-Medium" textAlign="left">
              {provider === InsuranceProvider.BATTLEFACE && (
                <Trans
                  i18nKey={t(`insurance_service.providers.battleface.terms_and_conditions`)}
                  components={[
                    <ExternalLink href="https://documents.battleface.com/terms_and_conditions/cbx-important-notices-and-disclosures.pdf" />,
                    <ExternalLink href="http://partner.battleface.com/library/cbx" />,
                    <ExternalLink href="/documents/TravelMedicalProtection.pdf" />,
                    <ExternalLink href="https://library.battleface.com/ineligible-destinations/" />,
                  ]}
                />
              )}
              {/* TODO: Change links to the correct ones */}
              {provider === InsuranceProvider.PANAMERICAN && (
                <Trans
                  i18nKey={t(`insurance_service.providers.panamerican.terms_and_conditions`)}
                  components={[
                    <ExternalLink href={language === 'es' ? 'https://palig.com/es/mx/seguro-viajero-cbx' : 'https://palig.com/en/mx/seguro-viajero-cbx'} />,
                    <ExternalLink href={language === 'es' ? 'https://palig.com/es/mx/política-de-privacidad' : 'https://palig.com/en/mx/privacy-policy'} />,
                    <ExternalLink
                      href={
                        language === 'es'
                          ? 'https://cbx-fe.s3.us-east-2.amazonaws.com/api/Mas_informacion_ESP_v3.pdf'
                          : 'https://cbx-fe.s3.us-east-2.amazonaws.com/api/Mas_informacion+ENG_v2.pdf'
                      }
                    />,
                  ]}
                />
              )}
            </Typography>
          </Stack>
        </>
      )}
    </Stack>
  );
};
